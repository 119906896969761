import * as React from 'react'
import { RoomEvent, type LocalAudioTrack, type LocalVideoTrack } from 'livekit-client'
import { useMaybeRoomContext, useMediaDeviceSelect } from '@livekit/components-react'
import { Select, MenuItem, SelectChangeEvent } from '@common/components'

export interface MediaDeviceSelectProps
  extends Omit<React.HTMLAttributes<HTMLUListElement>, 'onError'> {
  kind: MediaDeviceKind
  onActiveDeviceChange?: (deviceId: string) => void
  onDeviceListChange?: (devices: MediaDeviceInfo[]) => void
  onDeviceSelectError?: (e: Error) => void
  initialSelection?: string
  exactMatch?: boolean
  track?: LocalAudioTrack | LocalVideoTrack
  requestPermissions?: boolean
  onError?: (e: Error) => void
}

const MediaDeviceSelect: (
  props: MediaDeviceSelectProps & React.RefAttributes<HTMLUListElement>,
) => React.ReactNode = React.forwardRef<HTMLUListElement, MediaDeviceSelectProps>(
  function MediaDeviceSelect(
    {
      kind,
      initialSelection,
      onActiveDeviceChange,
      onDeviceListChange,
      onDeviceSelectError,
      exactMatch,
      track,
      requestPermissions,
      onError,
      ...props
    }: MediaDeviceSelectProps,
    ref,
  ) {
    const room = useMaybeRoomContext()
    const handleError = React.useCallback(
      (e: Error) => {
        if (room) {
          // awkwardly emit the event from outside of the room, as we don't have other means to raise a MediaDeviceError
          room.emit(RoomEvent.MediaDevicesError, e)
        }
        onError?.(e)
      },
      [room, onError],
    )
    const { devices, activeDeviceId, setActiveMediaDevice } = useMediaDeviceSelect({
      kind,
      room,
      track,
      requestPermissions,
      onError: handleError,
    })
    React.useEffect(() => {
      if (initialSelection !== undefined) {
        setActiveMediaDevice(initialSelection)
      }
    }, [setActiveMediaDevice])

    React.useEffect(() => {
      if (typeof onDeviceListChange === 'function') {
        onDeviceListChange(devices)
      }
    }, [onDeviceListChange, devices])

    React.useEffect(() => {
      if (activeDeviceId && activeDeviceId !== '') {
        onActiveDeviceChange?.(activeDeviceId)
      }
    }, [activeDeviceId])

    const handleActiveDeviceChange = async (deviceId: string) => {
      try {
        await setActiveMediaDevice(deviceId, { exact: exactMatch })
      } catch (e) {
        if (e instanceof Error) {
          onDeviceSelectError?.(e)
        } else {
          throw e
        }
      }
    }

    return devices.length > 0 ? (
      <Select
        size='small'
        color='light'
        sx={{
          '& .MuiSelect-select': {
            color: 'light.main',
          },
        }}
        value={activeDeviceId || devices[0].deviceId}
        onChange={(event) => handleActiveDeviceChange(event.target.value as string)}
        fullWidth
      >
        {devices.map((device, index) => (
          <MenuItem key={index} value={device.deviceId}>
            {device.label}
          </MenuItem>
        ))}
      </Select>
    ) : null
  },
)

export default MediaDeviceSelect
