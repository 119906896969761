import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    paymentGetSubscriptionPrices: build.query<
      PaymentGetSubscriptionPricesApiResponse,
      PaymentGetSubscriptionPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/get_subscription_plans`,
        params: { currency: queryArg.currency },
      }),
    }),
    paymentCreateCheckout: build.mutation<
      PaymentCreateCheckoutApiResponse,
      PaymentCreateCheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/create_checkout`,
        method: 'POST',
        body: queryArg.createPaymentCheckoutRequest,
      }),
    }),
    paymentListUserPayment: build.query<
      PaymentListUserPaymentApiResponse,
      PaymentListUserPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/list_user_payments`,
        params: {
          start_time: queryArg.startTime,
          end_time: queryArg.endTime,
          status: queryArg.status,
        },
      }),
    }),
    paymentGetUserSubscription: build.query<
      PaymentGetUserSubscriptionApiResponse,
      PaymentGetUserSubscriptionApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/get_user_subscription` }),
    }),
    paymentCreatePortal: build.mutation<PaymentCreatePortalApiResponse, PaymentCreatePortalApiArg>({
      query: () => ({ url: `/api/v1/extensions/payment/create_portal`, method: 'POST' }),
    }),
    paymentStripeWebhooks: build.mutation<
      PaymentStripeWebhooksApiResponse,
      PaymentStripeWebhooksApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/stripe_webhooks`, method: 'POST' }),
    }),
    paymentPaymeeWebhooks: build.mutation<
      PaymentPaymeeWebhooksApiResponse,
      PaymentPaymeeWebhooksApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/paymee_webhooks`, method: 'POST' }),
    }),
    paymentGetCredits: build.query<PaymentGetCreditsApiResponse, PaymentGetCreditsApiArg>({
      query: () => ({ url: `/api/v1/extensions/payment/credits` }),
    }),
    paymentReportUsage: build.mutation<PaymentReportUsageApiResponse, PaymentReportUsageApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/report_usage`,
        method: 'POST',
        body: queryArg.reportUsageRequestObject,
      }),
    }),
    paymentAddStudentSeats: build.mutation<
      PaymentAddStudentSeatsApiResponse,
      PaymentAddStudentSeatsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/add_student_seats`,
        method: 'POST',
        body: queryArg.addStudentSeatsRequest,
      }),
    }),
    paymentGetStripeAccount: build.query<
      PaymentGetStripeAccountApiResponse,
      PaymentGetStripeAccountApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/stripe_account` }),
    }),
    paymentCreateStripeAccount: build.mutation<
      PaymentCreateStripeAccountApiResponse,
      PaymentCreateStripeAccountApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/create_stripe_account`, method: 'POST' }),
    }),
    paymentGenerateStripeOnboardingForm: build.mutation<
      PaymentGenerateStripeOnboardingFormApiResponse,
      PaymentGenerateStripeOnboardingFormApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/generate_onboarding_link`,
        method: 'POST',
        body: queryArg.generateOnboardingLinkRequest,
      }),
    }),
    paymentGenerateStripeLoginLink: build.mutation<
      PaymentGenerateStripeLoginLinkApiResponse,
      PaymentGenerateStripeLoginLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/generate_stripe_login_link`,
        method: 'POST',
        body: queryArg.generateOnboardingLinkRequest,
      }),
    }),
    paymentCreateTeacherCheckout: build.mutation<
      PaymentCreateTeacherCheckoutApiResponse,
      PaymentCreateTeacherCheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/create_teacher_checkout`,
        method: 'POST',
        body: queryArg.createTeacherCheckoutRequest,
      }),
    }),
    paymentGetTeacherSubscriptionPlans: build.query<
      PaymentGetTeacherSubscriptionPlansApiResponse,
      PaymentGetTeacherSubscriptionPlansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/teacher_subscription_plans`,
        params: { url: queryArg.url },
      }),
    }),
    paymentConnectStripeWebhooks: build.mutation<
      PaymentConnectStripeWebhooksApiResponse,
      PaymentConnectStripeWebhooksApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/payment/connect_stripe_webhooks`, method: 'POST' }),
    }),
    paymentCreateTeacherAiCheckout: build.mutation<
      PaymentCreateTeacherAiCheckoutApiResponse,
      PaymentCreateTeacherAiCheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/create_teacher_ai_checkout`,
        method: 'POST',
        body: queryArg.createTeacherAiCheckoutRequest,
      }),
    }),
    paymentGetStripeAccountByUserId: build.query<
      PaymentGetStripeAccountByUserIdApiResponse,
      PaymentGetStripeAccountByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/get_stripe_account/${queryArg.userId}`,
      }),
    }),
    paymentCancelUserSubscription: build.mutation<
      PaymentCancelUserSubscriptionApiResponse,
      PaymentCancelUserSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/payment/cancel_user_subscription`,
        method: 'POST',
        body: queryArg.cancelUserSubscriptionRequest,
      }),
    }),
    neopaymentSubscribe: build.mutation<NeopaymentSubscribeApiResponse, NeopaymentSubscribeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/subscribe`,
        method: 'POST',
        body: queryArg.subscriptionRequest,
      }),
    }),
    neopaymentUpgradePlan: build.mutation<
      NeopaymentUpgradePlanApiResponse,
      NeopaymentUpgradePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/upgrade`,
        method: 'POST',
        body: queryArg.upgradeRequest,
      }),
    }),
    neopaymentRetrieveSubscription: build.query<
      NeopaymentRetrieveSubscriptionApiResponse,
      NeopaymentRetrieveSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/subscription/${queryArg.subscriptionId}`,
      }),
    }),
    neopaymentStripeWebhook: build.mutation<
      NeopaymentStripeWebhookApiResponse,
      NeopaymentStripeWebhookApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/webhook`, method: 'POST' }),
    }),
    neopaymentListUserSubscriptions: build.query<
      NeopaymentListUserSubscriptionsApiResponse,
      NeopaymentListUserSubscriptionsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/user/subscriptions` }),
    }),
    neopaymentGetBillingPortal: build.query<
      NeopaymentGetBillingPortalApiResponse,
      NeopaymentGetBillingPortalApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/billing-portal` }),
    }),
    neopaymentGetAttendanceLimits: build.query<
      NeopaymentGetAttendanceLimitsApiResponse,
      NeopaymentGetAttendanceLimitsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/attendance-limits` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type PaymentGetSubscriptionPricesApiResponse =
  /** status 200 Successful Response */ SubscriptionPlanListResponse[]
export type PaymentGetSubscriptionPricesApiArg = {
  currency?: PaymentCurrencyEnum
}
export type PaymentCreateCheckoutApiResponse =
  /** status 200 Successful Response */ CreatePaymentCheckoutResponse
export type PaymentCreateCheckoutApiArg = {
  createPaymentCheckoutRequest: CreatePaymentCheckoutRequest
}
export type PaymentListUserPaymentApiResponse =
  /** status 200 Successful Response */ UserPaymentResponse[]
export type PaymentListUserPaymentApiArg = {
  startTime?: string | null
  endTime?: string | null
  status?: UserPaymentStatus | null
}
export type PaymentGetUserSubscriptionApiResponse = /** status 200 Successful Response */ any
export type PaymentGetUserSubscriptionApiArg = void
export type PaymentCreatePortalApiResponse = /** status 200 Successful Response */ any
export type PaymentCreatePortalApiArg = void
export type PaymentStripeWebhooksApiResponse = /** status 200 Successful Response */ any
export type PaymentStripeWebhooksApiArg = void
export type PaymentPaymeeWebhooksApiResponse = /** status 200 Successful Response */ any
export type PaymentPaymeeWebhooksApiArg = void
export type PaymentGetCreditsApiResponse = /** status 200 Successful Response */ GetCreditsResponse
export type PaymentGetCreditsApiArg = void
export type PaymentReportUsageApiResponse = /** status 200 Successful Response */ any
export type PaymentReportUsageApiArg = {
  reportUsageRequestObject: ReportUsageRequestObject
}
export type PaymentAddStudentSeatsApiResponse = /** status 200 Successful Response */ any
export type PaymentAddStudentSeatsApiArg = {
  addStudentSeatsRequest: AddStudentSeatsRequest
}
export type PaymentGetStripeAccountApiResponse =
  /** status 200 Successful Response */ GetStripeAccountResponse
export type PaymentGetStripeAccountApiArg = void
export type PaymentCreateStripeAccountApiResponse = /** status 201 Successful Response */ any
export type PaymentCreateStripeAccountApiArg = void
export type PaymentGenerateStripeOnboardingFormApiResponse =
  /** status 200 Successful Response */ any
export type PaymentGenerateStripeOnboardingFormApiArg = {
  generateOnboardingLinkRequest: GenerateOnboardingLinkRequest
}
export type PaymentGenerateStripeLoginLinkApiResponse = /** status 200 Successful Response */ any
export type PaymentGenerateStripeLoginLinkApiArg = {
  generateOnboardingLinkRequest: GenerateOnboardingLinkRequest
}
export type PaymentCreateTeacherCheckoutApiResponse = /** status 200 Successful Response */ any
export type PaymentCreateTeacherCheckoutApiArg = {
  createTeacherCheckoutRequest: CreateTeacherCheckoutRequest
}
export type PaymentGetTeacherSubscriptionPlansApiResponse =
  /** status 200 Successful Response */ any
export type PaymentGetTeacherSubscriptionPlansApiArg = {
  url: string
}
export type PaymentConnectStripeWebhooksApiResponse = /** status 200 Successful Response */ any
export type PaymentConnectStripeWebhooksApiArg = void
export type PaymentCreateTeacherAiCheckoutApiResponse = /** status 200 Successful Response */ any
export type PaymentCreateTeacherAiCheckoutApiArg = {
  createTeacherAiCheckoutRequest: CreateTeacherAiCheckoutRequest
}
export type PaymentGetStripeAccountByUserIdApiResponse =
  /** status 200 Successful Response */ GetStripeAccountResponse
export type PaymentGetStripeAccountByUserIdApiArg = {
  userId: number
}
export type PaymentCancelUserSubscriptionApiResponse = /** status 200 Successful Response */ any
export type PaymentCancelUserSubscriptionApiArg = {
  cancelUserSubscriptionRequest: CancelUserSubscriptionRequest
}
export type NeopaymentSubscribeApiResponse = /** status 200 Successful Response */ any
export type NeopaymentSubscribeApiArg = {
  subscriptionRequest: SubscriptionRequest
}
export type NeopaymentUpgradePlanApiResponse = /** status 200 Successful Response */ UpgradeResponse
export type NeopaymentUpgradePlanApiArg = {
  upgradeRequest: UpgradeRequest
}
export type NeopaymentRetrieveSubscriptionApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse
export type NeopaymentRetrieveSubscriptionApiArg = {
  subscriptionId: string
}
export type NeopaymentStripeWebhookApiResponse = /** status 200 Successful Response */ any
export type NeopaymentStripeWebhookApiArg = void
export type NeopaymentListUserSubscriptionsApiResponse =
  /** status 200 Successful Response */ UserSubscriptionsResponse
export type NeopaymentListUserSubscriptionsApiArg = void
export type NeopaymentGetBillingPortalApiResponse =
  /** status 200 Successful Response */ BillingPortalResponse
export type NeopaymentGetBillingPortalApiArg = void
export type NeopaymentGetAttendanceLimitsApiResponse =
  /** status 200 Successful Response */ AttendanceLimitsResponse
export type NeopaymentGetAttendanceLimitsApiArg = void
export type SubscriptionPlanListResponse = {
  id: number
  name: string
  price: string
  credits: number
  third_party_subscription_plan_id: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type PaymentCurrencyEnum = 'USD' | 'EUR' | 'TND'
export type CreatePaymentCheckoutResponse = {
  url: string
}
export type PaymentMethodEnum = 'stripe' | 'paymee'
export type CreatePaymentCheckoutRequest = {
  subscription_plan_id: string
  payment_method: PaymentMethodEnum
  user_promotion_id?: number | null
  cancel_url?: string | null
  return_url?: string | null
}
export type UserPaymentResponse = {
  id: number
  created_at: string
  price: number
  currency: string
  start_time: string
  end_time: string
}
export type UserPaymentStatus = 'pending' | 'paid' | 'unpaid'
export type GetCreditsResponse = {
  credits: number
}
export type ReportUsageRequestObject = {
  item_id: string
  quantity: number
}
export type AddStudentSeatsRequest = {
  quantity: number
}
export type GetStripeAccountResponse = {
  id?: number | null
  account_id?: string | null
  user_id?: number | null
  charges_enabled?: boolean | null
}
export type GenerateOnboardingLinkRequest = {
  account_id: string
}
export type CreateTeacherCheckoutRequest = {
  subscription_id: string
  cancel_url: string
  free_trial?: boolean | null
}
export type CreateTeacherAiCheckoutRequest = {
  subscription_id: string
  cancel_url: string
}
export type CancelUserSubscriptionRequest = {
  subscription_id: string
}
export type SubscriptionRequest = {
  /** Subscription plan */
  plan: string
  /** Subscription period */
  period?: string
}
export type UpgradeResponse = {
  status: string
  checkout_url?: string | null
}
export type UpgradeRequest = {
  /** New subscription plan */
  new_plan: string
}
export type SubscriptionResponse = {
  id: string
  status: string
  plan: string
  start_date?: string | null
  end_date?: string | null
  cancel_at_period_end?: boolean
}
export type UserSubscriptionsResponse = {
  subscriptions: SubscriptionResponse[]
}
export type BillingPortalResponse = {
  portal_url: string
}
export type AttendanceLimitsResponse = {
  weekly_limit: number
  current_count: number
  remaining_count: number
}
export const {
  usePaymentGetSubscriptionPricesQuery,
  useLazyPaymentGetSubscriptionPricesQuery,
  usePaymentCreateCheckoutMutation,
  usePaymentListUserPaymentQuery,
  useLazyPaymentListUserPaymentQuery,
  usePaymentGetUserSubscriptionQuery,
  useLazyPaymentGetUserSubscriptionQuery,
  usePaymentCreatePortalMutation,
  usePaymentStripeWebhooksMutation,
  usePaymentPaymeeWebhooksMutation,
  usePaymentGetCreditsQuery,
  useLazyPaymentGetCreditsQuery,
  usePaymentReportUsageMutation,
  usePaymentAddStudentSeatsMutation,
  usePaymentGetStripeAccountQuery,
  useLazyPaymentGetStripeAccountQuery,
  usePaymentCreateStripeAccountMutation,
  usePaymentGenerateStripeOnboardingFormMutation,
  usePaymentGenerateStripeLoginLinkMutation,
  usePaymentCreateTeacherCheckoutMutation,
  usePaymentGetTeacherSubscriptionPlansQuery,
  useLazyPaymentGetTeacherSubscriptionPlansQuery,
  usePaymentConnectStripeWebhooksMutation,
  usePaymentCreateTeacherAiCheckoutMutation,
  usePaymentGetStripeAccountByUserIdQuery,
  useLazyPaymentGetStripeAccountByUserIdQuery,
  usePaymentCancelUserSubscriptionMutation,
  useNeopaymentSubscribeMutation,
  useNeopaymentUpgradePlanMutation,
  useNeopaymentRetrieveSubscriptionQuery,
  useLazyNeopaymentRetrieveSubscriptionQuery,
  useNeopaymentStripeWebhookMutation,
  useNeopaymentListUserSubscriptionsQuery,
  useLazyNeopaymentListUserSubscriptionsQuery,
  useNeopaymentGetBillingPortalQuery,
  useLazyNeopaymentGetBillingPortalQuery,
  useNeopaymentGetAttendanceLimitsQuery,
  useLazyNeopaymentGetAttendanceLimitsQuery,
} = injectedRtkApi
