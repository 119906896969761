import {
  useEventDisableVideoUserEventMutation,
  useEventEnableVideoUserEventMutation,
  useEventMuteEventMutation,
  useEventUnmuteEventMutation,
} from '@app/services'
import { useToggle } from '@common/hooks'
import { useCurrentEvent } from '@features/event/hooks'
import KickStudentModal from '@features/panels/components/kick-student-modal'
import { LocalVideoTrack, RemoteVideoTrack } from 'livekit-client'
import React from 'react'
import { Box, Button, Stack, Popover, IconButton, Icon } from '@common/components'
import {
  RecordVoiceOverOutlinedIcon,
  MicOffOutlinedIcon,
  MicNoneOutlinedIcon,
  VideocamOutlinedIcon,
  VideocamOffOutlinedIcon,
  Person2OutlinedIcon,
  CancelOutlinedIcon,
  LoopIcon,
  PersonAddAltIcon,
  FlagIcon,
  MenuIcon,
} from '@common/icons'
import ReportUser from '@features/auth/components/report-user/report-user'
import FollowUser from '@features/auth/components/follow-user'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { motion } from 'motion/react'
import {
  FocusToggle,
  TrackReferenceOrPlaceholder,
  useRemoteParticipants,
} from '@livekit/components-react'

const PopoverMenu = (props: {
  trackReference: TrackReferenceOrPlaceholder
  muted?: boolean
  videoTrack?: LocalVideoTrack | RemoteVideoTrack
  id: string
  children: React.ReactNode
}) => {
  const { trackReference, muted, videoTrack, id, children } = props
  const { isHost } = useCurrentEvent()
  const { open, handleClose, handleOpen } = useToggle(false)
  const remoteParticipants = useRemoteParticipants()
  const [muteUser] = useEventMuteEventMutation()
  const [unmuteUser] = useEventUnmuteEventMutation()
  const [disableCamera] = useEventDisableVideoUserEventMutation()
  const [enableCamera] = useEventEnableVideoUserEventMutation()
  const { event } = useCurrentEvent()
  const handleReload = (userId: number) => {
    console.log(event?.users?.find((u) => u.id === userId))
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const timeoutRef = React.useRef<NodeJS.Timeout>()

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    clearTimeout(timeoutRef.current)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    timeoutRef.current = setTimeout(() => {
      setAnchorEl(null)
    }, 100)
  }
  return (
    <>
      <KickStudentModal open={open} onClose={handleClose} id={id} />
      {id && isHost && id !== String(event?.host?.id) ? (
        <>
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              zIndex: 1,
              width: '100%',
              height: '100%',
            }}
          >
            {children}

            <IconMenu trackReference={trackReference} onMenuClick={handlePopoverOpen} />
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disablePortal
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Stack spacing={2} sx={{ p: 2 }}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <RecordVoiceOverOutlinedIcon sx={{ color: '#8F9BBA' }} />
                {muted === false ? (
                  <Button
                    fullWidth
                    startIcon={<MicOffOutlinedIcon />}
                    variant='outlined'
                    onClick={() => {
                      muteUser({ eventId: event?.event_id, userId: id })
                    }}
                  >
                    Turn off mic
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    startIcon={<MicNoneOutlinedIcon />}
                    variant='contained'
                    onClick={() => {
                      unmuteUser({ eventId: event?.event_id, userId: id })
                    }}
                  >
                    Ask to unmute
                  </Button>
                )}
              </Stack>
              <Stack direction='row' alignItems='center' spacing={2}>
                <VideocamOutlinedIcon sx={{ color: '#8F9BBA' }} />
                {videoTrack ? (
                  <Button
                    fullWidth
                    startIcon={<VideocamOffOutlinedIcon />}
                    variant='outlined'
                    onClick={() => {
                      disableCamera({ eventId: event?.event_id, userId: id })
                    }}
                  >
                    Turn off camera
                  </Button>
                ) : (
                  <Button
                    startIcon={<VideocamOutlinedIcon />}
                    variant='contained'
                    onClick={() => {
                      enableCamera({ eventId: event?.event_id, userId: id })
                    }}
                  >
                    Ask to turn on webcam
                  </Button>
                )}
              </Stack>
              <Stack direction='row' alignItems='center' spacing={2}>
                <PersonAddAltIcon sx={{ color: '#8F9BBA' }} />
                <FollowUser
                  userToFollow={event?.users?.find((u) => u.id === Number(id))}
                  sx={{ width: '100%', borderRadius: '5px' }}
                />
              </Stack>
              <Stack direction='row' alignItems='center' spacing={2}>
                <FlagIcon sx={{ color: '#8F9BBA' }} />
                <ReportUser
                  lightColor='rgba(255,255,255,0.7)'
                  userToReport={event?.users?.find((u) => u.id === Number(id))}
                  sx={{ width: '100%', borderRadius: '5px' }}
                />
              </Stack>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Person2OutlinedIcon sx={{ color: '#8F9BBA' }} />
                <Button
                  startIcon={<CancelOutlinedIcon />}
                  variant='outlined'
                  color='error'
                  fullWidth
                  onClick={handleOpen}
                >
                  Kick
                </Button>
              </Stack>
              {/* <Box sx={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                <LoopIcon sx={{ color: '#8F9BBA' }} />
                <Button
                  startIcon={<LoopIcon />}
                  variant='outlined'
                  color='primary'
                  onClick={() => handleReload(id)}
                >
                  Reload
                </Button>
              </Box> */}
            </Stack>
          </Popover>
        </>
      ) : (
        <>
          {children}
          {/* {remoteParticipants.length > 0 && (
            <IconButton
              sx={{
                position: 'absolute',
                top: '7px',
                right: '7px',
                zIndex: 110,
                backgroundColor: 'transparent',
              }}
            >
              <FocusToggle
                trackRef={trackReference}
                style={{
                  backgroundColor: 'transparent',
                  maxWidth: '24px',
                  maxHeight: '24px',
                  padding: '8px',
                }}
              />
            </IconButton>
          )} */}
        </>
      )}
    </>
  )
}

const MotionButton = motion.create(IconButton)

const IconMenu = (props: {
  trackReference: TrackReferenceOrPlaceholder
  onMenuClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}) => {
  const { trackReference, onMenuClick } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const itemProps = {
    tabIndex: isOpen ? 0 : -1,
    sx: isOpen
      ? {}
      : {
          position: 'absolute',
          top: '0',
          left: '0',
        },
    animate: {
      opacity: isOpen ? 1 : 0,
    },
    transition: {
      type: 'spring',
      bounce: 0.15,
      duration: 0.2,
    },
  }

  return (
    <motion.div
      layout
      style={{
        display: 'flex',
        position: 'absolute',
        top: '7px',
        right: '7px',
        gap: 1,
        zIndex: 110,
      }}
    >
      <MotionButton layout {...itemProps} onClick={onMenuClick}>
        <MoreVertIcon />
      </MotionButton>
      <MotionButton layout {...itemProps}>
        <FocusToggle
          trackRef={trackReference}
          style={{
            backgroundColor: 'transparent',
            maxWidth: '24px',
            maxHeight: '24px',
            padding: '8px',
          }}
        />
      </MotionButton>
      <MotionButton layout onClick={() => setIsOpen(!isOpen)} sx={{ zIndex: 30 }}>
        <MenuIcon />
      </MotionButton>
    </motion.div>
  )
}

export default PopoverMenu
