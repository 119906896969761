import { styled } from '@common/utils/styles'
import { Box } from '@common/components'

export const BoxContainer = styled(Box)(() => ({
  zIndex: '100',
  borderRadius: '5px',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  position: 'relative',
}))
