import { Modal, Box, Typography, Button } from '@common/components'
import { CheckCircleIcon } from '@common/icons'
import { keyframes } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@features/theme/theme.hooks'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const popIn = keyframes`
  0% {
    transform: scale(0) rotate(-45deg);
    opacity: 0;
  }
  60% {
    transform: scale(1.2) rotate(5deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0);
  }
`

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`

const StyledBox = styled(Box)({
  animation: `${fadeIn} 0.6s ease-out`,
})

const PartyPopper = styled('div')({
  animation: `${popIn} 0.6s ease-out, ${float} 2s ease-in-out infinite`,
  fontSize: '64px',
  marginBottom: '20px',
})

const ShimmerText = styled(Typography)(({ theme }) => ({
  background:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(90deg, #3787FF 25%, #5B9EFF 50%, #3787FF 75%)'
      : 'linear-gradient(90deg, #2563eb 25%, #3787FF 50%, #2563eb 75%)',
  backgroundSize: '200% auto',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${shimmer} 3s linear infinite`,
}))

const planDisplayNames = {
  basic: 'SpeakDuo Basic',
  pro: 'SpeakDuo Pro',
  team: 'SpeakDuo Team',
}

interface SuccessfullSubscriptionModalProps {
  open: boolean
  onClose: () => void
  plan?: 'basic' | 'pro' | 'team'
}

const SuccessfullSubscriptionModal = ({
  open,
  onClose,
  plan,
}: SuccessfullSubscriptionModalProps) => {
  const { isDark, theme } = useTheme()
  const displayPlan = plan ? planDisplayNames[plan] : 'Unknown Plan'

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ zIndex: 999999999 }}
      paperProps={{
        sx: {
          pt: 4,
          pb: 4,
          px: 4,
          width: '500px',
          maxWidth: '90%',
          textAlign: 'center',
          borderRadius: '16px',
          background: isDark ? 'linear-gradient(145deg, #1a1a1a, #2d2d2d)' : 'white',
          boxShadow: isDark ? '0 8px 32px rgba(0, 0, 0, 0.4)' : '0 8px 32px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <StyledBox>
        <PartyPopper>🎉</PartyPopper>

        <ShimmerText
          variant='h4'
          sx={{
            fontWeight: 800,
            mb: 2,
          }}
        >
          Welcome to {displayPlan}!
        </ShimmerText>

        <Typography
          sx={{
            color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.8)' : 'text.secondary',
            mb: 4,
            maxWidth: '400px',
            mx: 'auto',
            lineHeight: 1.6,
            fontSize: '1.1rem',
          }}
        >
          Your subscription has been successfully activated. Get ready to unlock all the premium
          features and take your language learning to the next level!
        </Typography>

        <Button
          variant='contained'
          onClick={onClose}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: '12px',
            background: 'linear-gradient(45deg, #3787FF, #5B9EFF)',
            boxShadow: '0 4px 12px rgba(55, 135, 255, 0.3)',
            transition: 'all 0.2s ease',
            fontSize: '1.1rem',
            textTransform: 'none',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 16px rgba(55, 135, 255, 0.4)',
              background: 'linear-gradient(45deg, #3787FF, #5B9EFF)',
            },
            '&:active': {
              transform: 'translateY(-1px)',
            },
          }}
        >
          Start Exploring
        </Button>
      </StyledBox>
    </Modal>
  )
}

export default SuccessfullSubscriptionModal
