import { revisionApi } from '@app/services'
import { store } from '@app/store'
import { defer } from '@lib/router'

const feedbackByIdLoader = async ({ params }: { params: { feedback_id: string | number } }) => {
  const feedback = await promise(params.feedback_id)

  return defer({ feedback })
}
const promise = async (feedbackId: string | number) => {
  const state = store.getState()
  if (!state.auth.tokens) {
    await new Promise<void>((resolve) => {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        if (state.auth.tokens) {
          unsubscribe()
          resolve()
        }
      })
    })
  }

  const { data: feedback } = await store.dispatch(
    revisionApi.endpoints.revisionGetRecording.initiate({ id: Number(feedbackId) }),
  )
  if (!feedback) {
    return null
  }

  return feedback
}

export default feedbackByIdLoader
