import React from 'react'
import { Avatar, Badge, Button, IconButton, Modal, Stack, Typography } from '@common/components'
import { useToggle } from '@common/hooks'
import { refreshTokenIfExpired } from '@features/auth/utils/refresh-token-if-expired'
import axios from 'axios'
import { API_BASE_URL } from '@config'
import { VisuallyHiddenInput } from './update-account-form.styles'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { AddAPhotoOutlinedIcon, PencilIcon } from '@common/icons'

type propsType = {
  handleUpload: (newImage: string) => void
  avatar?: string
  width?: string
  height?: string
  showIcon?: boolean
}

export const ModalUpdateImage = (props: propsType) => {
  const {
    handleUpload,
    avatar,
    width = '55px',
    height = '55px',
    showIcon = true,
    ...restProps
  } = props
  const { open, handleOpen, handleClose } = useToggle(false)
  const [image, setImage] = React.useState(null)
  const [cropper, setCropper] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleFileInputChange = (e) => {
    e.preventDefault()
    const fileReader = new FileReader()
    fileReader.onload = () => {
      setImage(fileReader.result)
    }
    fileReader.readAsDataURL(e.target.files[0])
    handleOpen()
    e.target.value = null
  }

  const handleCrop = async () => {
    const canvas = cropper.getCroppedCanvas()
    canvas.toBlob(async (blob) => {
      const formData = new FormData()
      formData.append('file', blob, 'image.png')
      const token = await refreshTokenIfExpired()
      try {
        const { data } = await axios.post(
          `${API_BASE_URL}/api/v1/extensions/storage/upload/testing-pengguin`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: () => {
              setIsLoading(true)
            },
          },
        )
        handleUpload(data.url)
        setIsLoading(false)
        handleClose()
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <Badge
        color='secondary'
        overlap='circular'
        invisible={!showIcon}
        badgeContent={<PencilIcon fontSize='small' />}
        sx={{
          '.MuiBadge-badge': {
            pointerEvents: 'none',
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            width: '25px',
            height: '25px',
            borderRadius: '25px',
          },
        }}
      >
        <IconButton
          component='label'
          onChange={handleFileInputChange}
          color='neutral'
          sx={{
            position: 'relative',
            width: width,
            height: height,
            '&:hover .icon': {
              display: 'flex',
            },
            '&:hover .avatar': {
              opacity: 0.5,
            },
          }}
          {...restProps}
        >
          <VisuallyHiddenInput type='file' accept='image/png, image/jpeg' />
          <AddAPhotoOutlinedIcon
            className='icon'
            sx={{
              zIndex: '100',
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
              boxShadow: 2,
              display: 'none',
              transition: 'opacity 0.3s ease-in-out, display 0.3s ease-in-out',
            }}
          />
          <Avatar
            className='avatar'
            src={avatar}
            alt={'profile image'}
            sx={{
              width: width,
              height: height,
              transition: 'opacity 0.2s ease-in-out',
            }}
          />
        </IconButton>
      </Badge>
      <Modal
        open={open}
        onClose={handleClose}
        paperProps={{
          sx: {
            borderRadius: '20px',
            p: 3,
            // maxHeight: '500px',
            width: '500px',
            maxWidth: '90%',
            textAlign: 'center',
          },
        }}
      >
        <Stack justifyContent='center' alignItems='center'>
          <Typography variant='h6'>Edit Image</Typography>
          <Cropper
            style={{ height: 400, width: '100%' }}
            src={image}
            initialAspectRatio={1}
            guides={false}
            viewMode={1}
            minCropBoxWidth={200}
            minCropBoxHeight={200}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance)
            }}
          />
          <Stack
            direction='row'
            alignItems={'center'}
            justifyContent={'flex-end'}
            sx={{ mt: 2, width: '100%' }}
            spacing={2}
          >
            <Button
              disabled={isLoading}
              onClick={handleClose}
              variant='outlined'
              color='error'
              sx={{ width: '70px', borderRadius: '5px' }}
            >
              cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={handleCrop}
              variant='outlined'
              color='info'
              sx={{ width: '70px', borderRadius: '5px' }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
