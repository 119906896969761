import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    eventGetEventConfig: build.query<EventGetEventConfigApiResponse, EventGetEventConfigApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/event/${queryArg.eventId}/config` }),
    }),
    eventScheduleEvent: build.mutation<EventScheduleEventApiResponse, EventScheduleEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event`,
        method: 'POST',
        body: queryArg.scheduleEventRequest,
      }),
    }),
    eventJoinEvent: build.mutation<EventJoinEventApiResponse, EventJoinEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/join`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    eventExitEvent: build.mutation<EventExitEventApiResponse, EventExitEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    eventGetCurrentEvent: build.query<EventGetCurrentEventApiResponse, EventGetCurrentEventApiArg>({
      query: () => ({ url: `/api/v1/sdk/event/event/current` }),
    }),
    eventGetEvents: build.query<EventGetEventsApiResponse, EventGetEventsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/events`,
        params: {
          event_type: queryArg.eventType,
          event_languages: queryArg.eventLanguages,
          start_time_utc: queryArg.startTimeUtc,
          end_time_utc: queryArg.endTimeUtc,
          proficiency: queryArg.proficiency,
          limit: queryArg.limit,
          offset: queryArg.offset,
          is_dashboard: queryArg.isDashboard,
          is_live: queryArg.isLive,
        },
      }),
    }),
    eventDeleteEvent: build.mutation<EventDeleteEventApiResponse, EventDeleteEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}`,
        method: 'DELETE',
      }),
    }),
    eventGetEventById: build.query<EventGetEventByIdApiResponse, EventGetEventByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/event/event/${queryArg.eventId}` }),
    }),
    eventModifyEvent: build.mutation<EventModifyEventApiResponse, EventModifyEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}`,
        method: 'PUT',
        body: queryArg.updateEventRequest,
      }),
    }),
    eventMuteEvent: build.mutation<EventMuteEventApiResponse, EventMuteEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/mute`,
        method: 'POST',
        params: { user_id: queryArg.userId, mute_type: queryArg.muteType },
      }),
    }),
    eventUnmuteEvent: build.mutation<EventUnmuteEventApiResponse, EventUnmuteEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/unmute`,
        method: 'POST',
        params: { user_id: queryArg.userId, mute_type: queryArg.muteType },
      }),
    }),
    eventDisableVideoUserEvent: build.mutation<
      EventDisableVideoUserEventApiResponse,
      EventDisableVideoUserEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/disable_video/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    eventEnableVideoUserEvent: build.mutation<
      EventEnableVideoUserEventApiResponse,
      EventEnableVideoUserEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/enable_video/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    eventEventLockingRooms: build.mutation<
      EventEventLockingRoomsApiResponse,
      EventEventLockingRoomsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/locking`,
        method: 'POST',
        params: { lock: queryArg.lock },
      }),
    }),
    eventParticipateInEvent: build.mutation<
      EventParticipateInEventApiResponse,
      EventParticipateInEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/participate`,
        method: 'POST',
      }),
    }),
    eventDeleteParticipationInEvent: build.mutation<
      EventDeleteParticipationInEventApiResponse,
      EventDeleteParticipationInEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/participate`,
        method: 'DELETE',
      }),
    }),
    eventChangeNativeSpeaker: build.mutation<
      EventChangeNativeSpeakerApiResponse,
      EventChangeNativeSpeakerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/change_native_speaker`,
        method: 'POST',
        body: queryArg.changeEventNativeSpeakerRequest,
      }),
    }),
    eventEventAutomaticMatch: build.query<
      EventEventAutomaticMatchApiResponse,
      EventEventAutomaticMatchApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/event/match` }),
    }),
    eventStartNow: build.mutation<EventStartNowApiResponse, EventStartNowApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/start_now`,
        method: 'POST',
      }),
    }),
    eventPersistTextMessage: build.mutation<
      EventPersistTextMessageApiResponse,
      EventPersistTextMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/chat`,
        method: 'POST',
        body: queryArg.sendMessageRequest,
      }),
    }),
    eventGetChatMessages: build.query<EventGetChatMessagesApiResponse, EventGetChatMessagesApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/chat`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    eventKickUser: build.mutation<EventKickUserApiResponse, EventKickUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/kick`,
        method: 'POST',
        body: queryArg.kickUserRequest,
      }),
    }),
    eventMatchUsers: build.mutation<EventMatchUsersApiResponse, EventMatchUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/match-users`,
        method: 'POST',
      }),
    }),
    eventEndEvent: build.mutation<EventEndEventApiResponse, EventEndEventApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/end`,
        method: 'POST',
      }),
    }),
    eventGetLastMatch: build.query<EventGetLastMatchApiResponse, EventGetLastMatchApiArg>({
      query: () => ({ url: `/api/v1/sdk/event/user/last-match` }),
    }),
    eventGetTotalEventJoins: build.query<
      EventGetTotalEventJoinsApiResponse,
      EventGetTotalEventJoinsApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/event/user/event-joins` }),
    }),
    eventGetUserMatches: build.query<EventGetUserMatchesApiResponse, EventGetUserMatchesApiArg>({
      query: () => ({ url: `/api/v1/sdk/event/user/matches` }),
    }),
    eventRecordEventAttendance: build.mutation<
      EventRecordEventAttendanceApiResponse,
      EventRecordEventAttendanceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/event/${queryArg.eventId}/attendance`,
        method: 'POST',
      }),
    }),
    eventSendWsMessage: build.mutation<EventSendWsMessageApiResponse, EventSendWsMessageApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/event/send/ws/${queryArg.eventId}`,
        method: 'POST',
        body: queryArg.ext,
        params: { event_type: queryArg.eventType, for_user_id: queryArg.forUserId },
      }),
    }),
    eventCanJoinEvent: build.query<EventCanJoinEventApiResponse, EventCanJoinEventApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/event/event/${queryArg.eventId}/can-join` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type EventGetEventConfigApiResponse = /** status 200 Successful Response */ EventConfig
export type EventGetEventConfigApiArg = {
  eventId: string
}
export type EventScheduleEventApiResponse =
  /** status 201 Successful Response */ CreateNewEventResponse
export type EventScheduleEventApiArg = {
  scheduleEventRequest: ScheduleEventRequest
}
export type EventJoinEventApiResponse = /** status 200 Successful Response */ any
export type EventJoinEventApiArg = {
  eventId: string
  exit?: boolean
}
export type EventExitEventApiResponse = /** status 200 Successful Response */ any
export type EventExitEventApiArg = {
  eventId: string
}
export type EventGetCurrentEventApiResponse =
  /** status 200 Successful Response */ EventResponse | null
export type EventGetCurrentEventApiArg = void
export type EventGetEventsApiResponse = /** status 200 Successful Response */ EventsFilterResponse
export type EventGetEventsApiArg = {
  eventType?: GameType | null
  eventLanguages?: string[]
  startTimeUtc?: string | null
  endTimeUtc?: string | null
  proficiency?: LanguageExperience | null
  limit?: number | null
  offset?: number | null
  isDashboard?: boolean | null
  isLive?: boolean | null
}
export type EventDeleteEventApiResponse = /** status 200 Successful Response */ any
export type EventDeleteEventApiArg = {
  eventId: string
}
export type EventGetEventByIdApiResponse = /** status 200 Successful Response */ EventResponse
export type EventGetEventByIdApiArg = {
  eventId: string
}
export type EventModifyEventApiResponse = /** status 200 Successful Response */ any
export type EventModifyEventApiArg = {
  eventId: string
  updateEventRequest: UpdateEventRequest
}
export type EventMuteEventApiResponse = /** status 200 Successful Response */ any
export type EventMuteEventApiArg = {
  eventId: string
  userId?: number | null
  muteType?: MuteTypeEnum | null
}
export type EventUnmuteEventApiResponse = /** status 200 Successful Response */ any
export type EventUnmuteEventApiArg = {
  eventId: string
  userId?: number | null
  muteType?: MuteTypeEnum | null
}
export type EventDisableVideoUserEventApiResponse = /** status 200 Successful Response */ any
export type EventDisableVideoUserEventApiArg = {
  eventId: string
  userId: number
}
export type EventEnableVideoUserEventApiResponse = /** status 200 Successful Response */ any
export type EventEnableVideoUserEventApiArg = {
  eventId: string
  userId: number
}
export type EventEventLockingRoomsApiResponse = /** status 201 Successful Response */ any
export type EventEventLockingRoomsApiArg = {
  eventId: string
  lock: boolean
}
export type EventParticipateInEventApiResponse = /** status 201 Successful Response */ any
export type EventParticipateInEventApiArg = {
  eventId: string
}
export type EventDeleteParticipationInEventApiResponse = /** status 200 Successful Response */ any
export type EventDeleteParticipationInEventApiArg = {
  eventId: string
}
export type EventChangeNativeSpeakerApiResponse = /** status 200 Successful Response */ any
export type EventChangeNativeSpeakerApiArg = {
  eventId: string
  changeEventNativeSpeakerRequest: ChangeEventNativeSpeakerRequest
}
export type EventEventAutomaticMatchApiResponse =
  /** status 200 Successful Response */ EventResponse
export type EventEventAutomaticMatchApiArg = void
export type EventStartNowApiResponse = /** status 200 Successful Response */ any
export type EventStartNowApiArg = {
  eventId: string
}
export type EventPersistTextMessageApiResponse = /** status 201 Successful Response */ any
export type EventPersistTextMessageApiArg = {
  eventId: string
  sendMessageRequest: SendMessageRequest
}
export type EventGetChatMessagesApiResponse =
  /** status 200 Successful Response */ ChatMessagesResponse[]
export type EventGetChatMessagesApiArg = {
  eventId: string
  limit?: number | null
  offset?: number | null
}
export type EventKickUserApiResponse = /** status 200 Successful Response */ any
export type EventKickUserApiArg = {
  eventId: string
  kickUserRequest: KickUserRequest
}
export type EventMatchUsersApiResponse = /** status 200 Successful Response */ any
export type EventMatchUsersApiArg = {
  eventId: string
}
export type EventEndEventApiResponse = /** status 200 Successful Response */ any
export type EventEndEventApiArg = {
  eventId: string
}
export type EventGetLastMatchApiResponse =
  /** status 200 Successful Response */ LastMatchResponse | null
export type EventGetLastMatchApiArg = void
export type EventGetTotalEventJoinsApiResponse =
  /** status 200 Successful Response */ UserEventJoinsResponse
export type EventGetTotalEventJoinsApiArg = void
export type EventGetUserMatchesApiResponse =
  /** status 200 Successful Response */ UserMatchesResponse
export type EventGetUserMatchesApiArg = void
export type EventRecordEventAttendanceApiResponse = /** status 201 Successful Response */ any
export type EventRecordEventAttendanceApiArg = {
  eventId: string
}
export type EventSendWsMessageApiResponse = /** status 200 Successful Response */ any
export type EventSendWsMessageApiArg = {
  eventId: string
  eventType: EventsEvtType
  forUserId?: number | null
  ext: RaiseHandEventEvt | RefreshEventEvt
}
export type EventCanJoinEventApiResponse = /** status 200 Successful Response */ any
export type EventCanJoinEventApiArg = {
  eventId: string
}
export type EventConfig = {
  min_number_users: number
  max_number_users: number
  default_ban_time: number
  non_subscribed_teacher?: object | null
  is_all_audio_muted?: boolean
  is_all_video_muted?: boolean
  are_rooms_locked?: boolean
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateNewEventResponse = {
  event_id: string
  short_event_id: string
}
export type GameType =
  | 'NONE'
  | 'GEO_GAME'
  | 'CAPTION_THIS'
  | 'HYPERBEAM'
  | 'PICTIONARY'
  | 'JUST_CHAT'
  | 'JKLM_FUN'
  | 'DISCUSSION'
  | 'WHITEBOARD'
  | 'CHAT'
  | 'GROUP_LESSON'
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type ScheduleEventRequest = {
  event_language: string
  event_type?: GameType | null
  proficiency?: LanguageExperience | null
  event_description?: string | null
  start_time_utc?: string | null
  end_time_utc?: string | null
  event_title?: string | null
  is_private?: boolean | null
  is_revision?: boolean | null
  tags?: string[] | null
  is_demo?: boolean | null
  duration?: number | null
}
export type Pronouns = 0 | 1 | 2 | 3
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type AddressInfoInDb = {
  country?: string | null
  city?: string | null
  state?: string | null
  address?: string | null
  zip_code?: string | null
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type Permission =
  | 'CREATE_EVENT'
  | 'CREATE_PENGGUIN_EVENT'
  | 'SEND_DIRECT_MESSAGE'
  | 'CREATE_CUSTOM_ROLE'
  | 'SEND_EMAIL_TO_USERS'
  | 'CREATE_GEOGUESSER_GAME'
  | 'JOIN_GEOGUESSER_GAME'
  | 'MAKE_GUESS_GEOGUESSER_GAME'
  | 'MAKE_RATING'
  | 'UPLOAD_FILE'
  | 'DELETE_ACCOUNT'
  | 'MAKE_ROLE'
  | 'ASSIGN_ROLE'
  | 'VIEW_ROLES'
  | 'EDIT_ROLE'
  | 'GENERATE_AGORA_TOKEN'
  | 'REFRESH_AGORA_TOKEN'
  | 'CREATE_AMBASSADOR'
  | 'DELETE_AMBASSADOR'
  | 'UPDATE_AMBASSADOR'
  | 'TRANSLATE'
  | 'CREATE_DISCORD_ROLE'
  | 'VIEW_DISCORD_ROLE'
  | 'CREATE_GEOGUESSER_PLACES'
  | 'HYPERBEAM_LIST_SESSIONS'
  | 'HYPERBEAM_DELETE_SESSIONS'
  | 'ADMIN_VIEW_USERS'
  | 'ADMIN_LANGUAGES'
  | 'ADMIN_AGORA_TOKENS_AUDIT'
  | 'ADMIN_ROLE'
  | 'ADMIN_DIRECT_MESSAGE'
  | 'ADMIN_AMBASSADORS'
  | 'ADMIN_DISCORD_ROLES'
  | 'ADMIN_DISCORD_AUDIT_EVENT'
  | 'ADMIN_DISCUSSION_LANGUAGE'
  | 'ADMIN_DISCUSSION_LANGUAGE_AUDIT_EVENTS'
  | 'ADMIN_USER_FLAGGING'
  | 'ADMIN_GEOGAME_ROUNDS'
  | 'ADMIN_GEOGAME_STATIC_COORDINATES'
  | 'ADMIN_GEOGAME_AUDIT_EVENTS'
  | 'ADMIN_EVENT_GAME_CHAT'
  | 'ADMIN_EVENT_CHAT'
  | 'ADMIN_EVENT_PARTICIPANTS'
  | 'ADMIN_PICTIONARY_AUDIT'
  | 'ADMIN_PICTIONARY_GAMES'
  | 'ADMIN_PICTIONARY_ROUNDS'
  | 'ADMIN_PICTIONARY_GUESS'
  | 'ADMIN_PICTIONARY_WORDS'
  | 'ADMIN_TRANSLATION_AUDIT'
  | 'ADMIN_REDIS_CONSOLE'
  | 'ADMIN_DEBUGGING_CONSOLE'
  | 'CREATE_TRANSCRIPT'
  | 'ADMIN_RECORDINGS'
export type RolesInDb = {
  id?: number | null
  name?: string | null
  permissions?: Permission[] | null
}
export type PublicUserProfile = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
  roles?: RolesInDb[] | null
  plan?: string | null
}
export type GameStatus = 'PENDING' | 'STARTED' | 'FINISHED' | 'CANCELED'
export type EventResponse = {
  id: number
  event_id: string
  event_type?: GameType | null
  current_room_id?: string | null
  event_language: string
  event_title?: string | null
  event_description?: string | null
  start_time_utc: string
  end_time_utc?: string | null
  host: PublicUserProfile
  proficiency: LanguageExperience
  participants: PublicUserProfile[]
  users?: PublicUserProfile[] | null
  is_live?: boolean
  short_event_id?: string | null
  status?: GameStatus | null
  is_private?: boolean | null
  tags?: string[] | null
  game_status?: GameStatus | null
  color_attribute?: string | null
  users_partition?: object
  duration?: number | null
}
export type EventsFilterResponse = {
  count: number
  events: EventResponse[]
}
export type UpdateEventRequest = {
  event_type?: GameType | null
  event_language?: string | null
  event_title?: string | null
  event_description?: string | null
  start_time_utc?: string | null
  end_time_utc?: string | null
  proficiency?: LanguageExperience | null
  tags?: string[] | null
  color_attribute?: string | null
}
export type MuteTypeEnum = 'AUDIO' | 'VIDEO' | 'BOTH'
export type ChangeEventNativeSpeakerRequest = {
  user_id: number
}
export type SendMessageRequest = {
  text: string
}
export type ChatMessagesResponse = {
  created_at: string
  username: string
  user_id: number
  text: string
  user_image?: string | null
  user_display_name?: string | null
}
export type KickUserRequest = {
  user_id: number
  ban_for_seconds?: number | null
}
export type LastMatchResponse = {
  match_id?: number | null
  matched_user_id?: number | null
  event_id?: number | null
  created_at?: string | null
}
export type UserEventJoinsResponse = {
  total_event_joins: number
}
export type BriefPublicUserProfile = {
  id: number
  username: string
  display_name?: string | null
  full_name?: string | null
  image?: string | null
}
export type MatchResponse = {
  match_id: number
  user_id: number
  matched_user_id: number
  event_id?: number | null
  user_matched_with?: BriefPublicUserProfile | null
  matched_at: string
  created_at: string
}
export type UserMatchesResponse = {
  matches: MatchResponse[]
}
export type EventsEvtType =
  | 'CHANGE_CURRENT_ROOM'
  | 'EXIT_ROOM'
  | 'JOIN_ROOM'
  | 'CAN_START_EVENT'
  | 'CHANGE_NATIVE_SPEAKER'
  | 'CHANGE_ROOM_LANGUAGE'
  | 'START_GAME'
  | 'EVENT_ENDED'
  | 'CAN_START_GAME'
  | 'JOIN_GAME'
  | 'END_GAME'
  | 'CHANGE_HOST'
  | 'CHANGE_EVENT_TYPE'
  | 'EVENT_LIVE'
  | 'CHANGE_WHITEBOARD_ROOM'
  | 'PARTICIPATE_SCHEDULED_EVENT'
  | 'DELETE_PARTICIPATION_IN_SCHEDULED_EVENT'
  | 'RECORDING_IS_READY'
  | 'RAISE_HAND'
  | 'REFRESH'
export type RaiseHandEventEvt = {
  event_at?: string
  event_id: string
  user_id: number
}
export type RefreshEventEvt = {
  event_at?: string
  event_id: string
}
export const {
  useEventGetEventConfigQuery,
  useLazyEventGetEventConfigQuery,
  useEventScheduleEventMutation,
  useEventJoinEventMutation,
  useEventExitEventMutation,
  useEventGetCurrentEventQuery,
  useLazyEventGetCurrentEventQuery,
  useEventGetEventsQuery,
  useLazyEventGetEventsQuery,
  useEventDeleteEventMutation,
  useEventGetEventByIdQuery,
  useLazyEventGetEventByIdQuery,
  useEventModifyEventMutation,
  useEventMuteEventMutation,
  useEventUnmuteEventMutation,
  useEventDisableVideoUserEventMutation,
  useEventEnableVideoUserEventMutation,
  useEventEventLockingRoomsMutation,
  useEventParticipateInEventMutation,
  useEventDeleteParticipationInEventMutation,
  useEventChangeNativeSpeakerMutation,
  useEventEventAutomaticMatchQuery,
  useLazyEventEventAutomaticMatchQuery,
  useEventStartNowMutation,
  useEventPersistTextMessageMutation,
  useEventGetChatMessagesQuery,
  useLazyEventGetChatMessagesQuery,
  useEventKickUserMutation,
  useEventMatchUsersMutation,
  useEventEndEventMutation,
  useEventGetLastMatchQuery,
  useLazyEventGetLastMatchQuery,
  useEventGetTotalEventJoinsQuery,
  useLazyEventGetTotalEventJoinsQuery,
  useEventGetUserMatchesQuery,
  useLazyEventGetUserMatchesQuery,
  useEventRecordEventAttendanceMutation,
  useEventSendWsMessageMutation,
  useEventCanJoinEventQuery,
  useLazyEventCanJoinEventQuery,
} = injectedRtkApi
