import { defer } from '@lib/router'
import { baseApi as api } from '@app/services/api'
import { store } from '@app/store'

const profileLoader = async () => {
  const user = promise()
  return defer({ user })
}

const promise = async () => {
  const state = store.getState()
  if (!state.auth.tokens) {
    await new Promise<void>((resolve) => {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        if (state.auth.tokens) {
          unsubscribe()
          resolve()
        }
      })
    })
  }

  // Get user & user modules
  const result = await Promise.all([
    store.dispatch(api.endpoints.authWhoami.initiate({}, { forceRefetch: true })),
    store.dispatch(api.endpoints.modulesGetUserModules.initiate({}, { forceRefetch: true })),
  ])

  // Then get the agora RTC + RTM (in parallel)
  return result
}

export default profileLoader
