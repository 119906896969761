import React from 'react'
import { motion } from 'motion/react'
import { Dot, SwitchContainer } from './switch-btn.styles'
import { Typography } from '@mui/material'

type SwitchBtnProps = {
  direction?: string
  firstLabel: string
  secondLabel: string
  onChange?: () => void
}

export const SwitchBtn = (props: SwitchBtnProps) => {
  const [switched, setSwitched] = React.useState(false)
  const { direction = 'horizontal', firstLabel, secondLabel, onChange } = props
  let boxStyles = {}
  let dotStyles = {}
  let textStyles = {}
  let variants = {
    label: {
      selected: { color: '#4681e0' },
      none: { opacity: 0.3 },
    },
  }
  if (firstLabel && secondLabel) {
    switch (direction) {
      case 'horizontal':
        boxStyles = {
          width: 300,
          height: 36,
        }
        dotStyles = {
          width: '50%',
          height: '100%',
        }
        textStyles = {
          firstLabel: { top: '50%', left: '25%', transform: 'translate(-50%,-50%)' },
          secondLabel: {
            top: '50%',
            right: '25%',
            transform: 'translate(50%,-50%)',
          },
        }
        variants = {
          ...variants,
          switch: {
            right: { x: ['0%', '100%'], y: '0%' },
            left: { x: ['100%', '0%'], y: '0%' },
          },
        }
        break
      case 'vertical':
        boxStyles = {
          width: 400,
          height: 70,
        }
        dotStyles = { height: '50%', width: '100%' }
        textStyles = {
          firstLabel: { top: 7, left: 0, right: 0 },
          secondLabel: {
            bottom: 7,
            right: 0,
            left: 0,
          },
        }
        variants = {
          ...variants,
          switch: {
            right: { y: ['0%', '100%'], x: '0%' },
            left: { y: ['100%', '0%'], x: '0%' },
          },
        }
        break
    }
  } else {
    boxStyles = {
      width: 400,
      height: 36,
    }
    dotStyles = {
      width: '100%',
      height: '100%',
    }
    textStyles = {
      firstLabel: { width: '100%', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' },
    }
    variants = {
      ...variants,
      switch: {
        right: { x: ['0%', '100%'], y: '0%' },
        left: { x: ['100%', '0%'], y: '0%' },
      },
    }
  }
  const handleSwitch = () => {
    if (firstLabel && secondLabel && onChange) {
      setSwitched((switched) => !switched)
      onChange()
    }
  }
  return (
    <SwitchContainer
      component={motion.div}
      sx={{
        ...boxStyles,
      }}
      onClick={handleSwitch}
    >
      <Typography
        component={motion.div}
        animate={switched ? 'none' : 'selected'}
        variants={variants.label}
        sx={{
          position: 'absolute',
          fontWeight: '500',
          ...textStyles.firstLabel,
        }}
        textAlign='center'
      >
        {firstLabel}
      </Typography>
      <Dot
        style={{
          ...dotStyles,
        }}
        animate={switched ? 'right' : 'left'}
        variants={variants.switch}
        transition={{ duration: 0.15 }}
      />
      <Typography
        component={motion.div}
        animate={switched ? 'selected' : 'none'}
        variants={variants.label}
        sx={{
          position: 'absolute',
          fontWeight: '500',
          ...textStyles.secondLabel,
        }}
        textAlign='center'
      >
        {secondLabel}
      </Typography>
    </SwitchContainer>
  )
}
