import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material/Button'
import { Button as MuiButton } from './button.styles'
import { forwardRef } from 'react'

const Button = forwardRef(
  <C extends React.ElementType>(
    props: {
      floating?: boolean
      loading?: boolean
    } & ButtonProps<C, { component?: C }>,
    ref: React.Ref<HTMLButtonElement>,
  ): React.ReactElement => {
    const { loading, ...restProps } = props

    if (typeof loading === 'boolean') {
      return <LoadingButton ref={ref} loading={loading} {...restProps} />
    }
    return <MuiButton ref={ref} floating={props.floating} {...restProps} />
  },
)

export default Button
export * from '@mui/material/Button'
Button.displayName = 'Button'
