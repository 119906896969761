import { Box, Button, Container, Grid, Link, Stack, Typography } from '@common/components'
import { MY_CLASSROOMS_ROUTE } from '@common/constants/routes'
import { useToggle } from '@common/hooks'
import CardPricing from '@features/pricing/components/card-pricing'
import SocialFeaturesModal from '@features/pricing/components/social-features-modal'
import { SwitchBtn } from '@features/pricing/components/switch-btn'
import { Link as RouteLink } from 'react-router-dom'
import { useUser } from '@features/auth/hooks'
import { keyframes } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { useSubscription } from '@features/pricing/hooks/use-subscription'

const pulseGlow = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(55, 135, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(55, 135, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(55, 135, 255, 0);
  }
`

const shine = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const AnimatedButton = styled(Button)({
  position: 'relative',
  overflow: 'hidden',
  background: 'linear-gradient(-45deg, #3787FF, #5B9EFF, #3787FF, #2563eb)',
  backgroundSize: '300% 300%',
  animation: `${shine} 3s ease infinite`,
  transition: 'all 0.3s ease',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '4px',
    animation: `${pulseGlow} 2s infinite`,
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 7px 14px rgba(55, 135, 255, 0.3), 0 3px 6px rgba(0, 0, 0, 0.1)',
    filter: 'brightness(1.1)',
  },
  '&:active': {
    transform: 'translateY(-1px)',
    boxShadow: '0 3px 8px rgba(55, 135, 255, 0.2)',
  },
})

const Pricing = () => {
  const { user } = useUser()
  const { open, handleOpen, handleClose } = useToggle(false)
  const { open: switchedOpt, handleToggle: handleToggleOpt } = useToggle(false)
  const { handleSubscribe, loadingPlan } = useSubscription()
  const plans = [
    {
      plan_id: 'free',
      plan: 'Free',
      price: '$0',
      description: 'Discover our community',
      features: ['3 sessions a week', 'Match with people at your level or 1 level lower'],
      callToAction: () => (
        <Button
          component={RouteLink}
          to={MY_CLASSROOMS_ROUTE}
          color={'neutral'}
          variant={'outlined'}
          sx={{
            mb: 3,
            alignSelf: 'center',
            width: '180px',
            fontWeight: 'bold',
          }}
        >
          Try it free
        </Button>
      ),
    },
    {
      plan_id: 'basic',
      plan: 'SpeakDuo Basic',
      price: switchedOpt ? '$7' : '$10',
      originalPrice: switchedOpt ? '$10' : undefined,
      discount: switchedOpt ? '30% off' : undefined,
      description: 'Join unlimited sessions, match with people at the same level, AI feedback ',
      features: [
        'Unlimited sessions',
        'AI grammar corrections',
        '10 x AI feedback',
        'Match with people at the same level',
        <span key={4}>
          Advanced{' '}
          <Link onClick={handleOpen} style={{ cursor: 'pointer' }}>
            social features {open}
          </Link>
        </span>,
      ],
      callToAction: () => (
        <Button
          onClick={() => handleSubscribe('basic', switchedOpt ? '6_month' : '1_month')}
          loading={loadingPlan === 'basic'}
          color={'neutral'}
          variant={'outlined'}
          sx={{
            mb: 3,
            alignSelf: 'center',
            width: '180px',
            fontWeight: 'bold',
          }}
        >
          Try it free
        </Button>
      ),
    },
    {
      plan_id: 'pro',
      popular: true,
      plan: 'SpeakDuo Pro',
      price: switchedOpt ? '$12.60' : '$18',
      originalPrice: switchedOpt ? '$18' : undefined,
      discount: switchedOpt ? '30% off' : undefined,
      description: 'Pronunciation feedback, priority matching at the same level or higher.',
      features: [
        'Unlimited sessions',
        'AI grammar corrections',
        'AI pronunciation and vocabulary feedback',
        'AI Interview Practice',
        '30 x AI feedback',
        'Priority matching: Match with people at the same level or higher ',
        <span key={4}>
          Advanced{' '}
          <Link onClick={handleOpen} style={{ cursor: 'pointer' }}>
            social features {open}
          </Link>
        </span>,
      ],
      callToAction: () => (
        <AnimatedButton
          onClick={() => handleSubscribe('pro', switchedOpt ? '6_month' : '1_month')}
          loading={loadingPlan === 'pro'}
          color='primary'
          variant='contained'
          sx={{
            mb: 3,
            alignSelf: 'center',
            width: '180px',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: '1rem',
            letterSpacing: '0.5px',
            borderRadius: '8px',
          }}
        >
          Try it free
        </AnimatedButton>
      ),
    },
    {
      highlighted: true,
      plan: 'Team',
      price: switchedOpt ? '$24.50' : '$35',
      originalPrice: switchedOpt ? '$35' : undefined,
      discount: switchedOpt ? '30% off' : undefined,
      description: 'For recruitment agencies,  teams and company-wide L&D English training',
      features: [
        'Administrator Dashboard',
        'Track member progress',
        'Customize and assign interview questions',
        'Manage video content',
      ],
      callToAction: () => (
        <Button
          onClick={() => handleSubscribe('team', switchedOpt ? '6_month' : '1_month')}
          loading={loadingPlan === 'team'}
          color={'neutral'}
          variant={'outlined'}
          sx={{
            color: 'white',
            border: '1px solid white',
            mb: 3,
            alignSelf: 'center',
            width: '180px',
            fontWeight: 'bold',
          }}
        >
          Try it free
        </Button>
      ),
    },
  ]
  return (
    <Box sx={{ mt: 3, pb: 15 }}>
      <Box sx={{ width: '88vw', maxWidth: '1392px', margin: '0 auto' }}>
        <Stack alignItems={'center'} spacing={1}>
          <Typography textAlign={'center'} fontWeight={600} variant='h4'>
            Pricing
          </Typography>
          <SwitchBtn
            direction={'horizontal'}
            onChange={handleToggleOpt}
            firstLabel='Monthly'
            secondLabel='6 Monthly'
          />
          <SocialFeaturesModal open={open} onClose={handleClose} />
          <Grid container spacing={1} sx={{ alignItems: 'stretch' }}>
            {plans.map((plan, index) => (
              <Grid key={index} item xs={12} md={3}>
                <CardPricing {...plan} isCurrentPlan={user ? user?.plan === plan.plan_id : false} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Box>
    </Box>
  )
}

export default Pricing
