import { Box, Button, Modal, Stack, Typography } from '@common/components'
import React from 'react'
import image from '@assets/images/penguin-interview.png'
import { CheckCircleOutlineIcon, LoopIcon } from '@common/icons'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'

const CompletedInterviewModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props
  if (!open) return null
  return (
    <Modal
      open={open}
      onClose={onClose}
      paperProps={{
        sx: {
          py: 4,
          px: 2,
          maxWidth: '550px',
          width: '95%',
          minHeight: '300px',
          borderRadius: '10px',
        },
      }}
    >
      <Box>
        <Stack spacing={2} alignItems='center' sx={{ px: 3, height: '100%', width: '100%' }}>
          <img src={image} alt='penguin-feedback' width={120} />
          <Typography fontWeight={600} fontSize={20}>
            Your recording is being processed.
          </Typography>
          <Stack direction='row' justifyContent='center' spacing={1} mt={2}>
            <LoopIcon color='info' fontSize='small' />
            <TrendingFlatIcon sx={{ color: 'text.secondary' }} fontSize='small' />
            <CheckCircleOutlineIcon fontSize='small' color='success' />
          </Stack>

          <Typography color='text.secondary' variant='caption'>
            You will see a green tick on your recording when processing is complete.
          </Typography>
          <Button variant='contained' onClick={onClose}>
            Sounds Good!
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default CompletedInterviewModal
