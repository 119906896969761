import { styled, Typography, Box, Paper } from '@mui/material'

export const ChatMessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'messagePosition',
})<{ messagePosition: string }>(({ messagePosition }) => ({
  display: 'flex',
  justifyContent: messagePosition === 'left' ? 'flex-start' : 'flex-end',
}))

export const MessageTypoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  width: '170px',
  borderRadius: '5px',
  padding: '8px 4px',
  display: 'block',
  height: '100%',
}))
export const MessageTypo = styled(Typography)({
  color: 'white',
  fontWeight: 400,
  wordBreak: 'break-word',
})
