import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    neopaymentSubscribe: build.mutation<NeopaymentSubscribeApiResponse, NeopaymentSubscribeApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/subscribe`,
        method: 'POST',
        body: queryArg.subscriptionRequest,
      }),
    }),
    neopaymentUpgradePlan: build.mutation<
      NeopaymentUpgradePlanApiResponse,
      NeopaymentUpgradePlanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/upgrade`,
        method: 'POST',
        body: queryArg.upgradeRequest,
      }),
    }),
    neopaymentRetrieveSubscription: build.query<
      NeopaymentRetrieveSubscriptionApiResponse,
      NeopaymentRetrieveSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/neopayment/subscription/${queryArg.subscriptionId}`,
      }),
    }),
    neopaymentStripeWebhook: build.mutation<
      NeopaymentStripeWebhookApiResponse,
      NeopaymentStripeWebhookApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/webhook`, method: 'POST' }),
    }),
    neopaymentListUserSubscriptions: build.query<
      NeopaymentListUserSubscriptionsApiResponse,
      NeopaymentListUserSubscriptionsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/user/subscriptions` }),
    }),
    neopaymentGetBillingPortal: build.query<
      NeopaymentGetBillingPortalApiResponse,
      NeopaymentGetBillingPortalApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/billing-portal` }),
    }),
    neopaymentGetAttendanceLimits: build.query<
      NeopaymentGetAttendanceLimitsApiResponse,
      NeopaymentGetAttendanceLimitsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/neopayment/attendance-limits` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type NeopaymentSubscribeApiResponse = /** status 200 Successful Response */ any
export type NeopaymentSubscribeApiArg = {
  subscriptionRequest: SubscriptionRequest
}
export type NeopaymentUpgradePlanApiResponse = /** status 200 Successful Response */ UpgradeResponse
export type NeopaymentUpgradePlanApiArg = {
  upgradeRequest: UpgradeRequest
}
export type NeopaymentRetrieveSubscriptionApiResponse =
  /** status 200 Successful Response */ SubscriptionResponse
export type NeopaymentRetrieveSubscriptionApiArg = {
  subscriptionId: string
}
export type NeopaymentStripeWebhookApiResponse = /** status 200 Successful Response */ any
export type NeopaymentStripeWebhookApiArg = void
export type NeopaymentListUserSubscriptionsApiResponse =
  /** status 200 Successful Response */ UserSubscriptionsResponse
export type NeopaymentListUserSubscriptionsApiArg = void
export type NeopaymentGetBillingPortalApiResponse =
  /** status 200 Successful Response */ BillingPortalResponse
export type NeopaymentGetBillingPortalApiArg = void
export type NeopaymentGetAttendanceLimitsApiResponse =
  /** status 200 Successful Response */ AttendanceLimitsResponse
export type NeopaymentGetAttendanceLimitsApiArg = void
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type SubscriptionRequest = {
  /** Subscription plan */
  plan: string
  /** Subscription period */
  period?: string
}
export type UpgradeResponse = {
  status: string
  checkout_url?: string | null
}
export type UpgradeRequest = {
  /** New subscription plan */
  new_plan: string
}
export type SubscriptionResponse = {
  id: string
  status: string
  plan: string
  start_date?: string | null
  end_date?: string | null
  cancel_at_period_end?: boolean
}
export type UserSubscriptionsResponse = {
  subscriptions: SubscriptionResponse[]
}
export type BillingPortalResponse = {
  portal_url: string
}
export type AttendanceLimitsResponse = {
  weekly_limit: number
  current_count: number
  remaining_count: number
}
export const {
  useNeopaymentSubscribeMutation,
  useNeopaymentUpgradePlanMutation,
  useNeopaymentRetrieveSubscriptionQuery,
  useLazyNeopaymentRetrieveSubscriptionQuery,
  useNeopaymentStripeWebhookMutation,
  useNeopaymentListUserSubscriptionsQuery,
  useLazyNeopaymentListUserSubscriptionsQuery,
  useNeopaymentGetBillingPortalQuery,
  useLazyNeopaymentGetBillingPortalQuery,
  useNeopaymentGetAttendanceLimitsQuery,
  useLazyNeopaymentGetAttendanceLimitsQuery,
} = injectedRtkApi
