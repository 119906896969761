import { Box, Button, Link, Modal, Paper, Stack, Typography } from '@common/components'
import React from 'react'
import image from '@assets/images/expectation/image2.png'
import { RouterLink } from '@lib/router'
import { PRICING_ROUTE } from '@common/constants/routes'

const AvoidLimitUpgradeModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props
  if (!open) return null
  return (
    <Modal
      open={open}
      onClose={onClose}
      paperProps={{
        sx: {
          py: 4,
          px: 2,
          maxWidth: '550px',
          width: '95%',
          minHeight: '300px',
          borderRadius: '10px',
        },
      }}
    >
      <Box>
        <Stack sx={{ px: 3, height: '100%' }}>
          <Typography fontWeight={600} fontSize={20}>
            Avoid Limit Reached
          </Typography>
          <img src={image} alt='unpaid-feedback' width={300} style={{ margin: '20px auto' }} />

          <Typography color='text.secondary'>
            You've reached your limit for avoiding users. Upgrade your account to avoid more users
            and customize your experience.
          </Typography>
          <Link component={RouterLink} to={PRICING_ROUTE}>
            Compare plans
          </Link>
          <Stack direction='row' justifyContent='center' spacing={2} mt={2}>
            <Button color='neutral' variant='outlined' sx={{ flex: 1 }}>
              Close
            </Button>
            <Button
              variant='contained'
              sx={{ flex: 1 }}
              component={RouterLink}
              to={PRICING_ROUTE}
              onClick={onClose}
            >
              Upgrade
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  )
}

export default AvoidLimitUpgradeModal
