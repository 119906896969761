import { RadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material'
import { Controller } from '@lib/form'

type ControllerRadioGroupProps = MuiRadioGroupProps & {
  control?: any
}

const ControllerRadioGroup: React.FC<ControllerRadioGroupProps> = (props) => {
  const { name, control, ...restProps } = props
  if (Boolean(control) && typeof name === 'string') {
    return (
      <Controller
        name={name}
        control={control}
        render={(args) => {
          return <RadioGroup {...restProps} {...args.field} />
        }}
      />
    )
  }
  return <RadioGroup name={name} {...restProps} />
}

export default ControllerRadioGroup
export * from '@mui/material/RadioGroup'
