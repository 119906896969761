import React from 'react'
import { IconButton as IconButtonMui, IconButtonProps } from '@mui/material'

// Create a wrapper for the IconButton with forwardRef
const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  return <IconButtonMui ref={ref} {...props} />
})

// Export the enhanced component
export default IconButton

// Optionally re-export everything from the original IconButton
export * from '@mui/material/IconButton'

IconButton.displayName = 'IconButton'
