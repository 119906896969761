import { Box } from '@common/components'
import { styled } from '@mui/material'
import { motion } from 'motion/react'

export const SwitchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '5px',
  backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(230,230,230,0.3)',
  borderRadius: '5px',
  cursor: 'pointer',
  position: 'relative',
  zIndex: '10',
}))
export const Dot = styled(motion.div)(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: 'white',
  zIndex: '-1',
}))
