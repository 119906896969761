import { styled } from '@common/utils/styles'
import { Box, Button } from '@common/components'

export const IconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  width: '25px',
  height: '25px',
}))

export const NameContainer = styled(Button)(({ theme }) => ({
  padding: '4px 8px 4px 8px',
  borderRadius: '30px',
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.neutral.main,
}))
